import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import coin from '../../img/coin.svg';
import bankingBg from '../../img/banking-bg.png';
import { color, breakpoints, button } from '../../utils/style';
import { lineCabinetLogin } from '../../components/Auth/Config';

const Wrapper = styled('div')`
  .breadcrumbs {
  }
  .error {
    right: 10% !important;
  }
`;

const Content = styled('div')`
  position: static;
  padding: 0px 0px 30px 0px;
  display: flex;
  border-radius: 8px;
  a {
    ${button};
    margin: 39px 0;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin-top: -15px;
  }
`;

const SideWallets = styled('div')`
  background-color: ${color.bunting};
  flex-basis: 50%;
  padding: 40px;
  border-radius: 0 8px 8px 0;
  @media (max-width: ${breakpoints.xs}) {
    padding: 0;
    background: none;
    border-radius: 8px;
  }
`;

const SideBg = styled('div')`
  background: url(${bankingBg}), #0d182c;
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: 34% 70%;
  min-height: 850px;
  padding: 80px 35px;
  flex-basis: 50%;
  border-radius: 8px 0 0 8px;
  opacity: 0.8;
  h1 {
    display: flex;
    position: static;
    white-space: no-wrap;
    //text-shadow: 0 6px 8px #003b6b, 0 0 14px rgba(0, 192, 255, 0.49), 0 -1px 1px rgba(0, 79, 123, 0.5),
    //  0 -1px 4px #00a8db;
    //-webkit-text-stroke: 1.4px #00c5ff;
    font-size: 50.4px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.lightningYellow};
    img {
      position: static;
    }
    span {
      white-space: normal;
      width: 100%;
      display: block;
    }
  }
  .breadcrumbs {
    padding: 10px 20px;
  }
  .gatsby-image-wrapper {
    border-radius: 24px 24px 0 0;
    img {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.tl}) {
    .iconImage {
      width: 30px;
    }
    h1 {
      font-size: 28px;
      left: 80px;
      top: 64px;
      img {
        left: -46px;
        top: 2px;
      }
    }
    .gatsby-image-wrapper {
      margin-bottom: 60px;
      border-radius: 8px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    height: 200px;
    min-height: 190px;
    margin-bottom: 20px;
    padding: 10px;
    flex-basis: 100%;
    background-position: top center;
    background-size: 85%;
    .breadcrumbs {
      position: absolute;
      margin: 10px 0;
      top: 10px;
      left: 40px;
    }

    .gatsby-image-wrapper {
      height: 140px;
      margin: 0 15px 82px;
    }
  }
`;

const SaButtonWrap = styled('div')`
  text-align: center;
  .button {
    ${button};
    margin: 0;
    padding: 10px 20px;
  }
  @media (max-width: ${breakpoints.xs}) {
    .button {
      white-space: normal;
      height: auto;
      padding: 12px 25px;
      width: 100%;
    }
  }
`;

const Banking = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedIds = pageContext.related;
  const { breadcrumbs } = pageContext;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Wrapper>
        <Breadcrumbs className="breadcrumbs" breadcrumbs={breadcrumbs} page_title={currentPage.title} />

        {relatedIds.length > 0 && (
          <Content>
            <SideBg>
              <h1>
                <img className="iconImage" src={coin} alt="coin" />
                <span>{currentPage.title}</span>
              </h1>
            </SideBg>
            <SideWallets>
              <SaButtonWrap>
                <a className="button pink-button" href={lineCabinetLogin} target="_blank" rel="noopener noreferrer">
                  LnwAsia Wallet ฝาก-ถอนเงินรูปแบบใหม่
                </a>
              </SaButtonWrap>
            </SideWallets>
          </Content>
        )}
      </Wrapper>
    </Layout>
  );
};

export default Banking;

export const pageQuery = graphql`
  query ($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "banking" } }) {
      nodes {
        id
        name
        slug
        items {
          type_label
          title
          object_slug
          url
          path
        }
      }
    }
  }
`;
